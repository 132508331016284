
.ClickToBeginOuter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ClickToBegin {
  margin: 0.2rem;
  border-width: 0;
  background: none;
  border-radius: 50%;
  width: 13rem;
  height: 13rem;
}

.ClickToBegin img {
  height: 12rem;
}

.ClickToBegin:hover {
  cursor: pointer;
}

.ClickToBegin:focus,
.ClickToBegin:hover {
  outline: none;
  background-color: #FFF3;
}

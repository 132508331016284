
.SwitchSong {
  height: 100%;
  width: 100%;
  object-fit: cover;
  padding: 7px;
  opacity: 0.6;
}

.SwitchSong:hover {
  cursor: pointer;
  opacity: 0.8;
}


.MeterThingOuter {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  text-align: center;
  align-content: center;
  justify-items: center;;
}

.MeterThingBar {
  pointer-events: none;
  transition: 0.05s transform linear;
  transform-origin: 0% 50%;
  margin: 1px 0;
}

.MeterThingSquare {
  pointer-events: none;
  transition: 0.05s opacity linear;
}

.MeterThingSquareOuter {
  box-sizing: border-box;
  border: 10px solid #FFF0;
  position: relative;
  border-radius: 50%;
  transition: 0.05s border-color linear;
}

.MeterThingNestedSquare {
  box-sizing: border-box;
  width: 94%;
  height: 94%;
  position: absolute;
  left: 3%;
  top: 3%;
  border: 10px solid #FFF0;
  pointer-events: none;
  transition: 0.05s border-color linear;
  border-radius: 50%;
}



.widget {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 5px;
}

.widgetContent {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  text-align: center;
  overflow: hidden;
}


.AboutButton {
  margin: 0.2rem;
  border-width: 0;
  background: none;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AboutButton img {
  height: 3.3rem;
}

.AboutButton:hover {
  cursor: pointer;
}

.AboutButton:focus,
.AboutButton:hover {
  outline: none;
  background-color: #FFF3;
}

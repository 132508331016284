

.LoadingOuter {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.LoadingBar {
  border: 1px solid #FFF;
  height: 0.75rem;
  width: 8rem;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.LoadingBarInner {
  background: linear-gradient(to right, #0000, #FFF);
  height: 100%;
  width: 100%;
  position: absolute;
  transform-origin: 50% 0%;
  animation-name: swipe;
  animation-delay: 0s;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes swipe {
  0% { transform: translateX(-100%);}
  100% { transform: translateX(100%);}
}


.LoadingImage {
  height: 20rem;
  margin-bottom: 2rem;
  transition: 10s opacity;
}
html { 
  box-sizing: border-box; 
  font-size: 16px;
} 

html *, html *:before, html *:after { 
  box-sizing: inherit; 
}

body {
  margin: 0;
  padding: 0;
  background-color: #222;
  color: #fff;
  font-family: 'Cairo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

:root {
  --variables-here: 5.6112423rem;
}

.hidden {
  display: none;
}



.SwapperContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}
.AppButtons {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  z-index: 100;
}
.imageWidgetOuter {
  height: 100%;
  width: 100%;
  object-fit: cover;
  padding: 7px;
  opacity: 0.8;
}

.imageWidgetOuter:hover {
  cursor: pointer;
  opacity: 1;
}

.PlayButton {
  margin: 0.2rem;
  border-width: 0;
  background: none;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
}

.PlayButton img {
  height: 3rem;
}

.PlayButton:hover {
  cursor: pointer;
}

.PlayButton:focus,
.PlayButton:hover {
  outline: none;
  background-color: #FFF3;
}
@keyframes FocusPlayButton {
  0% {background-color: #FFF3;}
  100% {background-color: #FFF0;}
}
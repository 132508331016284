
.widgetTextOuter {
  padding: 10%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.widgetTexts {
  -moz-user-select: none;
  -webkit-user-select: none;
}

.widgetTextOuter:hover {
  cursor: pointer;
}
.widgetSingleText {
  line-height: 0.5;
}
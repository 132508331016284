
.TestOutput {
  position: fixed;
  top: 20px;
  left: 20px;
  border: 1px solid white;
  color: white;
  background-color: #000D;
  padding: 0.5rem;
  z-index: 1000;
  pointer-events: none;
  opacity: 0.9;
}

.TestOutputItem {
  font-family: sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4rem;
  white-space: nowrap;
}

.activateTestOutputButton {
  margin: 1rem;
}